<template>
  <nav
    class="navbar navbar-expand-lg navbar-light px-3 px-sm-4 px-lg-4 px-xxl-5 container-xxl"
  >
    <router-link class="navbar-brand" to="/">
      <img src="@/assets/logo-atomic-x-red.svg" alt="Logo Atomic X" />
      Atomic X
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="toggleNav()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="collapse navbar-collapse justify-content-lg-end"
      id="navbarNavDropdown"
      ref="navbar"
    >
      <ul class="navbar-nav">
        <li class="nav-item" v-for="item in links" :key="item.name">
          <router-link class="nav-link px-lg-4" :to="item.link">
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "TopNavigation",
  methods: {
    toggleNav() {
      this.$refs.navbar.classList.toggle("collapse");
    }
  },
  watch: {
    '$route' () {
      this.$refs.navbar.classList.toggle("collapse");
    }
  },
  data() {
    return {
      links: [
        {
          name: "About",
          link: "/about",
        },
        // {
        //   name: "Services",
        //   link: "/services",
        // },
        {
          name: "Products",
          link: "/products",
        },
        // {
        //   name: "News",
        //   link: "/news",
        // },
        {
          name: "Contact",
          link: "/contact",
        },
         {
          name: "Careers",
          link: "/careers",
        },
      ],
    };
  },
};
</script>

<style></style>
